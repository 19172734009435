var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full rounded shadow flex flex-col" }, [
    _c(
      "div",
      {
        staticClass: "w-full flex items-center p-2 bg-gray-200 justify-between",
        class: { "cursor-pointer hover:bg-gray-300": _vm.isFounded },
        on: { click: _vm.onExpandAll },
      },
      [
        _c(
          "div",
          [
            _c("span", { staticClass: "mr-5" }, [_vm._v(_vm._s(_vm.data.id))]),
            _vm._v(" "),
            _c("status-pill", { attrs: { status: _vm.data.status } }),
            _vm._l(_vm.summaryPills, function (pill, index) {
              return _c(
                "sun-pill",
                {
                  key: `${pill.text}_${index}`,
                  staticClass: "ml-2",
                  attrs: { color: pill.color },
                },
                [_vm._v(" " + _vm._s(pill.text) + " ")]
              )
            }),
          ],
          2
        ),
        _vm.isFounded
          ? _c("sun-arrow-down-svg", {
              staticClass: "w-6 h-6",
              class: { "rotate-180": _vm.isExpanded },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm.isExpanded
      ? _c(
          "div",
          { staticClass: "w-full" },
          [
            _c(
              "collapsible-container",
              {
                staticClass: "m-2 border rounded shadow",
                attrs: { value: !!_vm.data.impressions.length },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "p-0 m-0" }, [
                            _vm._v("Impressions"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3550482226
                ),
              },
              [
                _vm.data.impressions.length === 0
                  ? _c("div", { staticClass: "flex p-2" }, [_vm._v("N/A")])
                  : _c(
                      "div",
                      [
                        _c("click-session-orchestathor", {
                          attrs: {
                            headers: _vm.impressionsHeaders,
                            data: _vm.data.impressions,
                            type: _vm.typeValues.IMPRESSIONS,
                          },
                        }),
                      ],
                      1
                    ),
              ]
            ),
            _c(
              "collapsible-container",
              {
                staticClass: "m-2 border rounded shadow",
                attrs: { value: !!_vm.data.clicks.length },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "p-0 m-0" }, [
                            _vm._v("Clicks"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3808277431
                ),
              },
              [
                _vm.data.clicks.length === 0
                  ? _c("div", { staticClass: "flex p-2" }, [_vm._v("N/A")])
                  : _c(
                      "div",
                      [
                        _c("click-session-orchestathor", {
                          attrs: {
                            headers: _vm.clickHeaders,
                            data: _vm.data.clicks,
                            type: _vm.typeValues.CLICKS,
                          },
                        }),
                      ],
                      1
                    ),
              ]
            ),
            _c(
              "collapsible-container",
              {
                staticClass: "m-2 border rounded shadow",
                attrs: { value: !!_vm.data.conversions.length },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "p-0 m-0" }, [
                            _vm._v("Conversions"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3931430369
                ),
              },
              [
                _vm.data.conversions.length === 0
                  ? _c("div", { staticClass: "flex p-2" }, [_vm._v("N/A")])
                  : _c(
                      "div",
                      [
                        _c("click-session-orchestathor", {
                          attrs: {
                            headers: _vm.leadsHeaders,
                            data: _vm.data.conversions,
                            type: _vm.typeValues.LEADS,
                          },
                        }),
                      ],
                      1
                    ),
              ]
            ),
            _c(
              "collapsible-container",
              {
                staticClass: "m-2 border rounded shadow",
                attrs: { value: !!_vm.data.postEvents.length },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "p-0 m-0" }, [
                            _vm._v("Post Events"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1653808365
                ),
              },
              [
                _vm.data.postEvents.length === 0
                  ? _c("div", { staticClass: "flex p-2" }, [_vm._v("N/A")])
                  : _c(
                      "div",
                      [
                        _c("click-session-orchestathor", {
                          attrs: {
                            headers: _vm.postEventsHeaders,
                            data: _vm.data.postEvents,
                            type: _vm.typeValues.POST_EVENTS,
                          },
                        }),
                      ],
                      1
                    ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }