var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    _vm._l(_vm.data, function (entity, index) {
      return _c(
        "div",
        {
          key: `session_${index}`,
          staticClass: "w-full lg:grid lg:grid-cols-3 p-4 border-t",
          attrs: { id: "entity_wrapper" },
        },
        _vm._l(_vm.headers, function (header) {
          return _c(
            "sun-label-group",
            {
              key: header.title,
              staticClass: "p-2 py-3",
              class: [
                header.classes,
                header.title === "Date Click" && _vm.isClickOrImpressions
                  ? "col-span-3"
                  : "",
                header.title === "Transaction" && !_vm.isPostEvents
                  ? "col-span-3"
                  : "",
              ],
              attrs: { text: header.title },
            },
            [
              header.title === "Reason"
                ? _c(
                    "p",
                    {
                      staticClass: "text-left text-sm",
                      class: [
                        !_vm.getErrorOrValidation(entity, "id") &&
                        entity.error_id === 0
                          ? "text-green-600"
                          : "text-red-600",
                      ],
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getErrorOrValidation(entity, "description") ||
                              "OK"
                          ) +
                          " "
                      ),
                    ]
                  )
                : header.title === "Suspicious"
                ? _c(
                    "p",
                    {
                      staticClass: "text-left text-sm",
                      class: [
                        _vm.getErrorOrValidation(entity, "id")
                          ? "text-red-600"
                          : "",
                      ],
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getErrorOrValidation(entity, "id")) +
                          " "
                      ),
                    ]
                  )
                : header.title === "Reason Type"
                ? _c(
                    "p",
                    {
                      staticClass: "text-left text-sm",
                      class: [
                        _vm.getErrorOrValidation(entity, "type")
                          ? "text-red-600"
                          : "",
                      ],
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getErrorOrValidation(entity, "type") || "N/A"
                          ) +
                          " "
                      ),
                    ]
                  )
                : header.title === "CTIT"
                ? _c("p", { staticClass: "text-sm" }, [
                    _vm._v(_vm._s(entity[header.value]) + " sec"),
                  ])
                : header.title === "Advertiser"
                ? _c(
                    "p",
                    { staticClass: "hyperlink-detail" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: _vm.advertiserNavigate(entity) } },
                        [_vm._v(" " + _vm._s(entity[header.value]) + " ")]
                      ),
                    ],
                    1
                  )
                : header.title === "Campaign"
                ? _c(
                    "p",
                    { staticClass: "hyperlink-detail" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: _vm.campaignNavigate(entity) } },
                        [_vm._v(" " + _vm._s(entity[header.value]) + " ")]
                      ),
                    ],
                    1
                  )
                : header.title === "Offer"
                ? _c(
                    "p",
                    { staticClass: "hyperlink-detail" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: _vm.offerNavigate(entity) } },
                        [_vm._v(" " + _vm._s(entity[header.value]) + " ")]
                      ),
                    ],
                    1
                  )
                : header.title === "Publisher"
                ? _c(
                    "p",
                    { staticClass: "hyperlink-detail" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: _vm.publisherNavigate(entity) } },
                        [_vm._v(" " + _vm._s(entity[header.value]) + " ")]
                      ),
                    ],
                    1
                  )
                : header.title === "IP"
                ? _c(
                    "p",
                    { staticClass: "pl-2 flex text-sm items-center" },
                    [
                      _c(
                        "sun-popover",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            position: "top",
                            "text-info": entity.countryName,
                          },
                        },
                        [
                          _c("country-flag", {
                            staticClass: "align-middle",
                            attrs: {
                              country: entity.countryCode,
                              size: "normal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" " + _vm._s(entity[header.value]) + " "),
                    ],
                    1
                  )
                : header.title === "Revenue"
                ? _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currency")(
                            entity[header.value],
                            entity.event_currency,
                            entity.countryCode,
                            2
                          )
                        ) +
                        " "
                    ),
                  ])
                : header.title === "Expense"
                ? _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currency")(
                            entity[header.value],
                            entity.event_currency,
                            entity.countryCode,
                            2
                          )
                        ) +
                        " " +
                        _vm._s(`(${entity.pub_commission} %)`) +
                        " "
                    ),
                  ])
                : header.title === "Profit"
                ? _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currency")(
                            entity[header.value],
                            entity.event_currency,
                            entity.countryCode,
                            2
                          )
                        ) +
                        " " +
                        _vm._s(`(${entity.commission} %)`) +
                        " "
                    ),
                  ])
                : _c("p", { staticClass: "pl-2 text-sm text-left" }, [
                    _vm._v(" " + _vm._s(entity[header.value] || "N/A") + " "),
                  ]),
            ]
          )
        }),
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }