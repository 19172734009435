<template>
  <div>
    <asterix-section>
      <template #header-title>Check Click ID</template>
      <template #content>
        <div class="w-full p-6 bg-white rounded-lg shadow-md lg:p-12 mb-6">
          <sun-form @submit="onFormSubmit" @keypress.enter="onFormSubmit">
            <sun-label-group text="Session ID">
              <div class="w-full">
                <sun-text-area
                  :value="sessionId"
                  name="sessionId"
                  placeholder="Session ID"
                  resize="resize-y"
                  required="required"
                  :disabled="loading"
                  @change="sessionId = $event.value"
                />
              </div>
            </sun-label-group>
            <div class="flex mt-4">
              <sun-button
                class="text-white bg-gray-700 rounded shadow-md hover:bg-gray-900 custom-p-1 md:w-auto text-xs"
                color="gray"
                variant="pill"
                :disabled="loading || !sessionId"
                :loading="loading"
                @click="onFormSubmit"
              >
                Check
              </sun-button>
            </div>
          </sun-form>
        </div>
        <div v-if="clickSessions && !loading" class="w-full p-6 bg-white rounded-lg shadow-md mb-12">
          <click-session-id
            v-for="session in clickSessions"
            :key="session.id"
            :data="session"
            :headers="clickSessionsHeaders"
            class="session-data"
          />
        </div>
      </template>
    </asterix-section>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import AsterixSection from '@/components/templates/AsterixSection';
import { getRawDataByEventSessionId } from '@/services/modules/ecommerce/analytics';
import { indexMixin } from '@/mixins/index/indexMixin';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import ClickSessionId from '@/components/molecules/modules/ecommerce/analytics/ClickSessionId';
import { CLICK_SESSION_ID_HEADERS } from '@/components/molecules/modules/ecommerce/analytics/config';

export default {
  name: 'ClickId',
  components: {
    AsterixSection,
    // CollapsibleContainer,
    // InfoEventSession,
    ClickSessionId,
  },
  mixins: [indexMixin],
  data: () => ({
    sessionId: null,
    loading: false,
    clickSessions: null,
    clickSessionsHeaders: CLICK_SESSION_ID_HEADERS,
  }),
  computed: {
    formattedSessionId() {
      return this.sessionId.replaceAll('\n', ',').replaceAll(' ', ',');
    },
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async onFormSubmit() {
      try {
        this.loading = true;
        const { data } = await getRawDataByEventSessionId(this.activeClientId, this.formattedSessionId);
        this.clickSessions = data.clickSessions.sort((a, b) => a.status > b.status);
        this.createToast(Toast.success('Info Event Session', 'Info loaded successfully'));
      } catch (e) {
        this.createToast(Toast.error(`Can't get data from event`, e.message));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.session-data {
  @apply mb-5;
}
.session-data:last-child {
  @apply mb-0;
}
</style>
