var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        scopedSlots: _vm._u([
          {
            key: "header-title",
            fn: function () {
              return [_vm._v("Check Click ID")]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full p-6 bg-white rounded-lg shadow-md lg:p-12 mb-6",
                  },
                  [
                    _c(
                      "sun-form",
                      {
                        on: {
                          submit: _vm.onFormSubmit,
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.onFormSubmit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "sun-label-group",
                          { attrs: { text: "Session ID" } },
                          [
                            _c(
                              "div",
                              { staticClass: "w-full" },
                              [
                                _c("sun-text-area", {
                                  attrs: {
                                    value: _vm.sessionId,
                                    name: "sessionId",
                                    placeholder: "Session ID",
                                    resize: "resize-y",
                                    required: "required",
                                    disabled: _vm.loading,
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.sessionId = $event.value
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "flex mt-4" },
                          [
                            _c(
                              "sun-button",
                              {
                                staticClass:
                                  "text-white bg-gray-700 rounded shadow-md hover:bg-gray-900 custom-p-1 md:w-auto text-xs",
                                attrs: {
                                  color: "gray",
                                  variant: "pill",
                                  disabled: _vm.loading || !_vm.sessionId,
                                  loading: _vm.loading,
                                },
                                on: { click: _vm.onFormSubmit },
                              },
                              [_vm._v(" Check ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.clickSessions && !_vm.loading
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "w-full p-6 bg-white rounded-lg shadow-md mb-12",
                      },
                      _vm._l(_vm.clickSessions, function (session) {
                        return _c("click-session-id", {
                          key: session.id,
                          staticClass: "session-data",
                          attrs: {
                            data: session,
                            headers: _vm.clickSessionsHeaders,
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }