<template>
  <div class="w-full">
    <div
      v-for="(entity, index) in data"
      id="entity_wrapper"
      :key="`session_${index}`"
      class="w-full lg:grid lg:grid-cols-3 p-4 border-t"
    >
      <sun-label-group
        v-for="header in headers"
        :key="header.title"
        :text="header.title"
        :class="[
          header.classes,
          header.title === 'Date Click' && isClickOrImpressions ? 'col-span-3' : '',
          header.title === 'Transaction' && !isPostEvents ? 'col-span-3' : '',
        ]"
        class="p-2 py-3"
      >
        <p
          v-if="header.title === 'Reason'"
          :class="[!getErrorOrValidation(entity, 'id') && entity.error_id === 0 ? 'text-green-600' : 'text-red-600']"
          class="text-left text-sm"
        >
          {{ getErrorOrValidation(entity, 'description') || 'OK' }}
        </p>
        <p
          v-else-if="header.title === 'Suspicious'"
          class="text-left text-sm"
          :class="[getErrorOrValidation(entity, 'id') ? 'text-red-600' : '']"
        >
          {{ getErrorOrValidation(entity, 'id') }}
        </p>
        <p
          v-else-if="header.title === 'Reason Type'"
          class="text-left text-sm"
          :class="[getErrorOrValidation(entity, 'type') ? 'text-red-600' : '']"
        >
          {{ getErrorOrValidation(entity, 'type') || 'N/A' }}
        </p>
        <p v-else-if="header.title === 'CTIT'" class="text-sm">{{ entity[header.value] }} sec</p>

        <p v-else-if="header.title === 'Advertiser'" class="hyperlink-detail">
          <router-link :to="advertiserNavigate(entity)">
            {{ entity[header.value] }}
          </router-link>
        </p>

        <p v-else-if="header.title === 'Campaign'" class="hyperlink-detail">
          <router-link :to="campaignNavigate(entity)">
            {{ entity[header.value] }}
          </router-link>
        </p>

        <p v-else-if="header.title === 'Offer'" class="hyperlink-detail">
          <router-link :to="offerNavigate(entity)">
            {{ entity[header.value] }}
          </router-link>
        </p>

        <p v-else-if="header.title === 'Publisher'" class="hyperlink-detail">
          <router-link :to="publisherNavigate(entity)">
            {{ entity[header.value] }}
          </router-link>
        </p>

        <p v-else-if="header.title === 'IP'" class="pl-2 flex text-sm items-center">
          <sun-popover class="mr-2" position="top" :text-info="entity.countryName">
            <country-flag class="align-middle" :country="entity.countryCode" size="normal" />
          </sun-popover>
          {{ entity[header.value] }}
        </p>
        <p v-else-if="header.title === 'Revenue'" class="text-sm">
          {{ entity[header.value] | currency(entity.event_currency, entity.countryCode, 2) }}
        </p>
        <p v-else-if="header.title === 'Expense'" class="text-sm">
          {{ entity[header.value] | currency(entity.event_currency, entity.countryCode, 2) }}
          {{ `(${entity.pub_commission} %)` }}
        </p>
        <p v-else-if="header.title === 'Profit'" class="text-sm">
          {{ entity[header.value] | currency(entity.event_currency, entity.countryCode, 2) }}
          {{ `(${entity.commission} %)` }}
        </p>
        <p v-else class="pl-2 text-sm text-left">
          {{ entity[header.value] || 'N/A' }}
        </p>
      </sun-label-group>
    </div>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import { typeValues } from '@/components/molecules/modules/ecommerce/analytics/config';
import { edit as advertiserEdit } from '@/router/private/modules/ecommerce/demand/advertiser/edit';
import { edit as campaignEdit } from '@/router/private/modules/ecommerce/demand/campaign/edit';
import { edit as offerEdit } from '@/router/private/modules/ecommerce/demand/offer/edit';
import { edit as publisherEdit } from '@/router/private/modules/ecommerce/supply/publisher/edit';

export default {
  name: 'ClickSessionOrchestathor',
  components: {
    CountryFlag,
  },
  props: {
    headers: {
      type: Array,
      default: () => null,
    },
    data: {
      type: Array,
      default: () => null,
    },
    type: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      advertiserEdit,
      campaignEdit,
      offerEdit,
      publisherEdit,
      typeValues,
    };
  },
  computed: {
    isClickOrImpressions() {
      return this.type === this.typeValues.CLICKS || this.type === this.typeValues.IMPRESSIONS;
    },
    isPostEvents() {
      return this.type === this.typeValues.POST_EVENTS;
    },
  },
  methods: {
    advertiserNavigate(entity) {
      return { name: this.advertiserEdit.name, params: { advertiserId: entity.ad } };
    },
    campaignNavigate(entity) {
      return { name: this.campaignEdit.name, params: { campaignId: entity.camp } };
    },
    offerNavigate(entity) {
      return { name: this.offerEdit.name, params: { offerId: entity.of } };
    },
    publisherNavigate(entity) {
      return { name: this.publisherEdit.name, params: { publisherId: entity.pb } };
    },
    getErrorOrValidation(entity, type) {
      if (!entity.error_id) {
        return entity[`validation_${type}`];
      }
      return entity[`error_${type}`];
    },
  },
};
</script>
<style>
#entity_wrapper:first-child {
  @apply border-t-0;
}
.hyperlink-detail {
  @apply underline cursor-pointer text-sm pl-2;
}
</style>
