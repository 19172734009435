<template>
  <div class="w-full rounded shadow flex flex-col">
    <div
      class="w-full flex items-center p-2 bg-gray-200 justify-between"
      :class="{ 'cursor-pointer hover:bg-gray-300': isFounded }"
      @click="onExpandAll"
    >
      <div>
        <span class="mr-5">{{ data.id }}</span> <status-pill :status="data.status" />
        <sun-pill v-for="(pill, index) in summaryPills" :key="`${pill.text}_${index}`" class="ml-2" :color="pill.color">
          {{ pill.text }}
        </sun-pill>
      </div>
      <sun-arrow-down-svg v-if="isFounded" class="w-6 h-6" :class="{ 'rotate-180': isExpanded }" />
    </div>
    <div v-if="isExpanded" class="w-full">
      <collapsible-container class="m-2 border rounded shadow" :value="!!data.impressions.length">
        <template #title><div class="p-0 m-0">Impressions</div></template>
        <div v-if="data.impressions.length === 0" class="flex p-2">N/A</div>
        <div v-else>
          <click-session-orchestathor
            :headers="impressionsHeaders"
            :data="data.impressions"
            :type="typeValues.IMPRESSIONS"
          />
        </div>
      </collapsible-container>
      <collapsible-container class="m-2 border rounded shadow" :value="!!data.clicks.length">
        <template #title>
          <div class="p-0 m-0">Clicks</div>
        </template>
        <div v-if="data.clicks.length === 0" class="flex p-2">N/A</div>
        <div v-else>
          <click-session-orchestathor :headers="clickHeaders" :data="data.clicks" :type="typeValues.CLICKS" />
        </div>
      </collapsible-container>
      <collapsible-container class="m-2 border rounded shadow" :value="!!data.conversions.length">
        <template #title><div class="p-0 m-0">Conversions</div></template>
        <div v-if="data.conversions.length === 0" class="flex p-2">N/A</div>
        <div v-else>
          <click-session-orchestathor :headers="leadsHeaders" :data="data.conversions" :type="typeValues.LEADS" />
        </div>
      </collapsible-container>
      <collapsible-container class="m-2 border rounded shadow" :value="!!data.postEvents.length">
        <template #title><div class="p-0 m-0">Post Events</div></template>
        <div v-if="data.postEvents.length === 0" class="flex p-2">N/A</div>
        <div v-else>
          <click-session-orchestathor
            :headers="postEventsHeaders"
            :data="data.postEvents"
            :type="typeValues.POST_EVENTS"
          />
        </div>
      </collapsible-container>
    </div>
  </div>
</template>

<script>
import StatusPill from '@/components/atoms/StatusPill';
import CollapsibleContainer from '@/components/atoms/CollapsibleContainer';
import ClickSessionOrchestathor from '@/components/molecules/modules/ecommerce/analytics/ClickSessionOrchestathor.vue';
import { typeValues } from '@/components/molecules/modules/ecommerce/analytics/config';
import SunPill from '@/components/atoms/SunPill/SunPill';

const statusTypes = {
  FOUND: 'FOUND',
  NOT_FOUND: 'NOT_FOUND',
};

export default {
  name: 'ClickSessionId',
  components: {
    StatusPill,
    CollapsibleContainer,
    ClickSessionOrchestathor,
    SunPill,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    headers: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      statusTypes,
      isExpanded: false,
      typeValues,
    };
  },
  computed: {
    isFounded() {
      return this.data.status === this.statusTypes.FOUND;
    },
    impressionsHeaders() {
      return this.headers
        .filter(
          header => header.availableTypes.length === 0 || header.availableTypes.includes(this.typeValues.IMPRESSIONS)
        )
        .filter(header => {
          if (header.value.includes('proxy')) {
            return this.data[header.value];
          }
          return true;
        });
    },
    clickHeaders() {
      return this.headers
        .filter(header => header.availableTypes.length === 0 || header.availableTypes.includes(this.typeValues.CLICKS))
        .filter(header => {
          if (header.value.includes('proxy')) {
            return this.data[header.value];
          }
          return true;
        });
    },
    leadsHeaders() {
      return this.headers
        .filter(header => header.availableTypes.length === 0 || header.availableTypes.includes(this.typeValues.LEADS))
        .filter(header => {
          if (header.value.includes('proxy')) {
            return this.data[header.value];
          }
          return true;
        });
    },
    postEventsHeaders() {
      return this.headers
        .filter(
          header => header.availableTypes.length === 0 || header.availableTypes.includes(this.typeValues.POST_EVENTS)
        )
        .filter(header => {
          if (header.value.includes('proxy')) {
            return this.data[header.value];
          }
          return true;
        });
    },
    summaryPills() {
      if (this.data.status === statusTypes.NOT_FOUND) return [];
      const pills = [];
      this.data.impressions.forEach(impression => {
        pills.push({
          text: 'impr',
          color: this.getPillColorByValidation(impression),
        });
      });
      this.data.clicks.forEach(click => {
        pills.push({ text: 'click', color: this.getPillColorByValidation(click) });
      });
      this.data.conversions.forEach(conversion => {
        pills.push({
          text: 'conv',
          color: this.getPillColorByValidation(conversion),
        });
      });
      this.data.postEvents.forEach(postEvent => {
        pills.push({
          text: 'postev',
          color: this.getPillColorByValidation(postEvent),
        });
      });
      return pills;
    },
  },
  mounted() {
    this.data.impressions.forEach(impression => {
      impression.time = this.formatDate(impression.time);
    });
    this.data.impressions.sort((a, b) => a.time - b.time);

    this.data.clicks.forEach(clicks => {
      clicks.time = this.formatDate(clicks.time);
    });
    this.data.clicks.sort((a, b) => a.time - b.time);

    this.data.conversions.forEach(conversion => {
      conversion.time = this.formatDate(conversion.time);
    });
    this.data.conversions.sort((a, b) => a.time - b.time);

    this.data.postEvents.forEach(postEvent => {
      postEvent.time = this.formatDate(postEvent.time);
    });
    this.data.postEvents.sort((a, b) => a.time - b.time);
  },
  methods: {
    onExpandAll() {
      if (this.isFounded) this.isExpanded = !this.isExpanded;
    },
    getPillColorByValidation({ error_id, validation_id }) {
      return error_id || validation_id ? 'red' : 'green';
    },
    formatDate(stringDate) {
      // dd/mm/yyyy hh:mm:ss this format is spected
      const splitStringDate = stringDate.split(' ');

      const splitDate = splitStringDate[0].split('/');
      const year = splitDate[2];
      const month = splitDate[1];
      const day = splitDate[0];

      const time = splitStringDate[1];

      return new Date(`${year}/${month}/${day} ${time}`);
    },
  },
};
</script>
<style scoped>
:deep(.collapsible-container-header:hover) {
  @apply bg-gray-200;
}

:deep(.collapsible-container-header) {
  @apply text-left items-center bg-gray-100 px-2 py-1;
}
.grid:last-child {
  @apply border-b-0;
}
</style>
